import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Header() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        mb: 1,
      }}>
      <Box sx={{ width: 200, mb: 2 }}>
        <Link to="/">
          <img
            width="100%"
            src="https://dinfucemstorage.blob.core.windows.net/image/dinfucem/logo.png"
            alt="logo"
          />
        </Link>
      </Box>
      <Box sx={{ fontSize: '18px', color: '#888' }}>114清明現場自助登記</Box>
    </Box>
  );
}
