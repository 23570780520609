import { Box } from '@mui/material';
import React from 'react';
import Header from './Header';
import StartPage from './StartPage';
import EntryPage from './EntryPage';
import ItemsPage from './ItemsPage';
import SuccessPage from './SuccessPage';

export default function SiteRegistration(props) {
  const control = React.useState({
    name: '',
    phone: '',
    items: [],
    stage: 'start',
  });
  const [state, setState] = control;

  return (
    <>
      <Box sx={{ p: 2, display: { xs: 'block', md: 'none' } }}>
        <Header />
        <Box>
          {state.stage === 'start' && <StartPage control={control} />}
          {state.stage === 'items' && <ItemsPage control={control} />}
          {state.stage === 'entry' && <EntryPage control={control} />}
          {state.stage === 'success' && <SuccessPage control={control} />}
        </Box>
      </Box>
      {/* 電腦顯示 */}
      <Box sx={{ p: 2, display: { xs: 'none', md: 'block' } }}>
        <h1>本頁面只支援手機顯示</h1>
      </Box>
    </>
  );
}
