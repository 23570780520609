import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';

import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import React from 'react';
import getProducts from './product-list';
import { useCart } from './cart-service';

// 金紙/供品、思親、優惠、接駁車
const cutoffs = JSON.parse(process.env.REACT_APP_CUTOFFS);

function Products({ hide }) {
  const control = React.useState('牌位');
  const event = process.env.REACT_APP_EVENT.substring(3);
  const products = getProducts(process.env.REACT_APP_EVENT);
  const soldouts = JSON.parse(process.env.REACT_APP_SOLDOUTS || '[]');

  if (DateTime.now() > DateTime.fromISO(hide)) return null;
  return (
    <Box
      sx={{
        maxWidth: 1000,
        margin: '0 auto',
        position: 'relative',
      }}>
      <Grid container spacing={2}>
        <Tab control={control}>牌位</Tab>
        <Tab control={control} cutoff={cutoffs[1]}>
          供品
        </Tab>
        <Tab control={control} cutoff={cutoffs[0]}>
          金紙
        </Tab>
        <Tab control={control}>其他</Tab>
        {event === '新春' && (
          <Tab control={control} cutoff={cutoffs[1]}>
            年度優惠
          </Tab>
        )}
      </Grid>
      <Box sx={{ mt: 4 }}>
        <TabContent name="牌位" active={control[0]}>
          <Product
            product={products['專區牌位']}
            soldout={soldouts.includes('專區牌位')}
          />
          <Product
            product={products['特種牌位']}
            soldout={soldouts.includes('特種牌位')}
          />
          {process.env.REACT_APP_EVENT.substring(3) !== '新春' && (
            <Product
              product={products['甲種牌位']}
              soldout={soldouts.includes('甲種牌位')}
            />
          )}
        </TabContent>
        <TabContent name="供品" active={control[0]}>
          <Product product={products['如意套組']} cutoff={cutoffs[0]} />
          <Product product={products['吉祥套組']} cutoff={cutoffs[0]} />
          <Product product={products['思親套組']} cutoff={cutoffs[1]} />
          {event !== '新春' && (
            <Product product={products['思親套組(2)']} cutoff={cutoffs[1]} />
          )}
        </TabContent>
        <TabContent name="金紙" active={control[0]}>
          {/* <Product product={products['黃金套組']} /> */}
          <Product product={products['富貴套組']} cutoff={cutoffs[0]} />
        </TabContent>
        <TabContent name="其他" active={control[0]}>
          <Product product={products['供燈']} />
          <Product product={products['光明燈']} />
          {event !== '新春' &&
            DateTime.utc() <
              DateTime.fromISO(cutoffs[0] + 'T00:00+08').minus({
                hours: 8,
              }) && <Shuttle />}
        </TabContent>
        {process.env.REACT_APP_EVENT.substring(3) === '新春' && (
          <TabContent name="年度優惠" active={control[0]}>
            <Product product={products['年度優惠-甲種']} />
            <Product product={products['年度優惠-特種']} />
            <Product product={products['年度優惠-如意']} cutoff={cutoffs[0]} />
            <Product product={products['年度優惠-吉祥']} cutoff={cutoffs[0]} />
            <Product product={products['年度優惠-思親']} cutoff={cutoffs[1]} />
          </TabContent>
        )}
      </Box>
    </Box>
  );
}
function Tab({ children, control, cutoff }) {
  const [active, setActive] = control;
  const isActive = active === children;
  if (
    DateTime.utc().setZone('Asia/Taipei') >=
    DateTime.fromISO(cutoff + 'T16:00+08')
  )
    return null;
  return (
    <Grid item xs={6} sm={6} md={3}>
      <Box
        onClick={() => setActive(children)}
        sx={{
          textAlign: 'center',
          padding: '8px 20px',
          borderRadius: 2,
          fontSize: {
            xs: '1.5rem',
            sm: '1.7rem',
          },
          color: isActive ? 'rgb(31,148,180)' : 'rgba(0,0,0,0.6)',
          backgroundColor: isActive ? 'rgb(223,240,253)' : 'rgba(0,0,0,0.25)',
          cursor: 'pointer',
        }}>
        {children}
      </Box>
    </Grid>
  );
}
function TabContent({ children, name, active }) {
  if (name !== active) return null;
  return (
    <Grid container spacing={3}>
      {children}
    </Grid>
  );
}
function Product({ product, soldout = false, cutoff }) {
  const { id, name, price, image } = product;
  if (
    DateTime.utc().setZone('Asia/Taipei') >=
    DateTime.fromISO(cutoff + 'T15:00+08')
  )
    return null;
  const ModeratedLink = soldout ? 'div' : Link;
  return (
    <Grid
      item
      xs={6}
      md={4}
      sx={{
        textAlign: 'center',
        '& a': {
          textDecoration: 'none',
        },
        '& div:not(:first-child)': {
          marginTop: 0.5,
          color: 'rgba(0,0,0,0.8)',
        },
      }}>
      <ModeratedLink to={`/product/${id}`}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <img
            width="95%"
            src={image}
            alt={name}
            style={{ filter: soldout ? 'grayscale(80%)' : null }}
          />
          {soldout && (
            <div
              style={{
                position: 'absolute',
                top: '50%',
                background: '#D2691E',
                width: '95%',
                margin: 'auto',
                fontSize: '20px',
                color: 'white',
                fontWeight: 'bold',
              }}>
              額滿
            </div>
          )}
        </div>
        <div style={{ color: soldout ? '#C0C0C0' : 'default' }}>{name}</div>
        <div style={{ color: soldout ? '#C0C0C0' : 'default' }}>
          ${price.toLocaleString()}
        </div>
      </ModeratedLink>
    </Grid>
  );
}
function Shuttle() {
  const [state, setState] = React.useState({
    date: 0,
    station: '博愛',
    time: '07:30',
    times: ['07:30', '08:30', '10:00'],
    party: 1,
    added: null,
  });
  const { cart, setCart } = useCart();
  const event = process.env.REACT_APP_EVENT.substring(3);
  const cutoff = cutoffs[1];
  if (event === '新春') return null;
  if (
    DateTime.utc() >= DateTime.fromISO(cutoff + 'T00:00+08').minus({ hours: 8 })
  )
    return null;

  function changeStation(event) {
    let update = { ...state, station: event.target.value };
    if (update.station !== '桃園') {
      update.times = ['07:30', '08:30', '10:00'];
    } else {
      update.times = ['08:00'];
    }
    if (!update.times.includes(update.time)) update.time = update.times[0];
    setState(update);
  }
  function handleAdd() {
    setCart((prev) => {
      const insert = {
        event: process.env.REACT_APP_EVENT,
        name: '接駁車',
        price: state.party * 500,
        shuttle: {
          date: state.date,
          station: state.station,
          time: state.time,
          party: state.party,
        },
        timestamp: Date.now(),
      };
      const newCart = {
        ...prev,
        items: [...prev.items, insert],
      };
      return newCart;
    });
    setState({
      date: 0,
      station: '博愛',
      time: '07:30',
      times: ['07:30', '08:30', '10:00'],
      party: 1,
      added: (
        <>
          <p>{`日期：${state.date === 0 ? '第一天' : '第二天'}`}</p>
          <p>{`接駁站：${state.station}`}</p>
          <p>{`時間：${state.time}`}</p>
          <p>{`人數：${state.party}`}</p>
        </>
      ),
    });
    setTimeout(() => {
      setState({ ...state, added: null });
    }, 5000);
  }

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          border: '1px solid rgba(0,0,0,0.3)',
          borderRadius: 1,
          mt: 1,
          p: 2,
        }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h1" sx={{ fontSize: 25 }}>
              法會接駁車
            </Typography>
          </Grid>
          {state.added ? (
            <Grid item xs={12}>
              <Typography
                variant="p"
                sx={{ fontSize: 20, my: 2 }}
                color="#219EBC">
                成功加入接駁車!
              </Typography>
              {state.added}
            </Grid>
          ) : (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>日期</InputLabel>
                  <Select
                    value={state.date}
                    label="日期"
                    onChange={(event) =>
                      setState({
                        ...state,
                        date: event.target.value,
                      })
                    }>
                    <MenuItem value={0}>第一天</MenuItem>
                    <MenuItem value={1}>第二天</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>接駁站</InputLabel>
                  <Select
                    value={state.station}
                    label="接駁站"
                    onChange={changeStation}>
                    <MenuItem value="博愛">博愛</MenuItem>
                    <MenuItem value="行天宮">行天宮</MenuItem>
                    <MenuItem value="板橋">板橋</MenuItem>
                    <MenuItem value="桃園">桃園</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel>時間</InputLabel>
                  <Select
                    value={state.time}
                    label="時間"
                    onChange={(event) =>
                      setState({
                        ...state,
                        time: event.target.value,
                      })
                    }>
                    {state.times.map((time, index) => (
                      <MenuItem id={index} value={time}>
                        {time}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  type="number"
                  label="人數"
                  value={state.party}
                  onChange={(event) => {
                    setState((prev) => ({
                      ...prev,
                      party: event.target.value,
                    }));
                  }}
                  onBlur={(event) => {
                    if (event.target.value === null || event.target.value < 1)
                      setState((prev) => ({ ...prev, party: 1 }));
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={handleAdd}
                  size="large"
                  fullWidth>
                  確定
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    </Grid>
  );
}

export default Products;
