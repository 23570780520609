import { Box, Button, Grid, IconButton, Typography } from '@mui/material';

import { DateTime } from 'luxon';
import EditIcon from '@mui/icons-material/Edit';
import React from 'react';
import axios from 'axios';

function getEvent() {
  let year = DateTime.now().year - 1911;
  let event = '新春';
  const month = DateTime.now().month;

  if (month === 11 || month === 12) year = year + 1;
  if (month >= 2 && month <= 5) event = '清明';
  if (month >= 6 && month <= 9) event = '中元';
  return `${year}${event}`;
}

export default function ItemsPage({ control }) {
  const [state, setState] = control;

  function handleEntry() {
    setState({ ...state, stage: 'entry' });
  }
  function handleEdit(index) {
    setState({ ...state, stage: 'entry', index });
  }
  async function handleNext() {
    try {
      await axios.post(
        process.env.REACT_APP_SERVER_URL + '/event/site-registration',
        {
          name: state.name,
          phone: state.phone,
          items: state.items.map((item) => {
            return {
              ...item,
              event: getEvent(),
            };
          }),
        }
      );
      setState({ ...state, stage: 'success' });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="p"
            sx={{ color: '#444', fontWeight: 'bold', fontSize: '20px' }}>
            第三步：確認報名資料
          </Typography>
        </Box>
      </Grid>
      {state.items.length === 0 && (
        <Grid item xs={12}>
          <Box
            sx={{ border: '1px solid #ccc', borderRadius: 2 }}
            onClick={handleEntry}>
            <Box sx={{ p: 5 }}>
              <Typography
                sx={{
                  fontSize: '20px',
                  color: '#888',
                  textAlign: 'center',
                }}>
                尚未輸入牌位，請輸入牌位
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
      {state.items.map((item, index) => (
        <Grid item xs={12}>
          <Box sx={{ border: '1px solid #ccc', borderRadius: 2 }}>
            <Box sx={{ borderBottom: '1px solid #ccc', p: 2 }}>
              <Grid container alignItems="center">
                <Grid xs={6}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontWeight: 'bold',
                      color: '#444',
                    }}>
                    {item.name}
                  </Typography>
                </Grid>
                <Grid
                  xs={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}>
                  <Typography
                    sx={{
                      fontSize: '20px',
                      color: '#444',
                      mr: 1,
                    }}>
                    ${(item.name === '特種牌位' ? 3000 : 2000).toLocaleString()}
                  </Typography>
                  <IconButton>
                    <EditIcon onClick={() => handleEdit(index)} />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ p: 2, pb: 4 }}>
              <Box sx={{ mb: 2 }}>
                <p
                  style={{
                    color: 'rgb(33, 158, 188)',
                    fontSize: 13,
                    fontWeight: 'bold',
                    marginBottom: 2,
                  }}>
                  被超薦人
                </p>
                {item.deceaseds.join(' ')}
              </Box>
              <Box>
                <p
                  style={{
                    color: 'rgb(33, 158, 188)',
                    fontSize: 13,
                    fontWeight: 'bold',
                    marginBottom: 2,
                  }}>
                  陽上
                </p>
                {item.family.join(' ')}
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="secondary"
          sx={{ mr: 2.5, p: 2, width: '150px' }}
          onClick={handleEntry}>
          輸入牌位
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ p: 2, width: '150px' }}
          onClick={handleNext}>
          完成
        </Button>
      </Grid>
    </Grid>
  );
}
