import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  TextField,
  Typography,
} from '@mui/material';

import React from 'react';

export default function EntryPage({ control }) {
  const [state, setState] = control;
  const [item, setItem] = React.useState({
    name: '特種牌位',
    deceaseds: new Array(2).fill(''),
    family: new Array(5).fill(''),
  });

  React.useEffect(() => {
    if (state.index == null) return;
    setItem(state.items[state.index]);
  }, [state.index]);

  function handleNext() {
    // check names
    const errors = {};
    if (item.deceaseds.every((d) => !d)) errors.noDeceased = '請輸入被超薦人';
    item.deceaseds.every((d, index) => {
      let result =
        /[\s,、，（）～！⋯⋯——「」｜：“”《》？~!@#$%^&*_+{}:"<>?/-;.`\[\]·|’‘¥€£•]/.test(
          d
        );
      if (result) {
        errors[`deceaseds${index}`] = true;
        errors.specialCharacter = '請勿輸入特殊符號';
      }
    });
    if (item.family.every((f) => !f)) errors.noFamily = '請輸入陽上';
    if (Object.keys(errors).length > 0) {
      return setItem((prev) => ({
        ...prev,
        errors,
      }));
    }
    const payload = { ...item };
    payload.errors = undefined;
    payload.family = payload.family.map((f) => f.slice(-2));
    if (state.index != null) {
      const items = [...state.items];
      items[state.index] = payload;
      return setState({ ...state, items, stage: 'items', index: null });
    } else {
      const items = [...state.items, payload];
      setState({ ...state, items, stage: 'items', index: null });
    }
  }
  function handleDelete() {
    setState({
      ...state,
      stage: 'items',
      items: state.items.filter((_, i) => i !== state.index),
      index: null,
    });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography
          variant="p"
          sx={{ color: '#444', fontWeight: 'bold', fontSize: '20px' }}>
          {state.index == null ? '第二步：輸入牌位資料' : '編輯牌位資料'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Radio
              checked={item.name === '特種牌位'}
              value="特種牌位"
              onChange={(event) =>
                setItem({ ...item, name: event.target.value })
              }
            />
          }
          label="特種牌位"
        />
        <FormControlLabel
          control={
            <Radio
              checked={item.name === '甲種牌位'}
              value="甲種牌位"
              onChange={(event) =>
                setItem({ ...item, name: event.target.value })
              }
            />
          }
          label="甲種牌位"
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="p" sx={{ fontWeight: 'bold' }}>
            被超薦人
          </Typography>
        </Box>
        <Box>
          <Typography variant="p" sx={{ fontSize: '12px', color: '#888' }}>
            最多兩位被超薦人
          </Typography>
        </Box>
        {item.errors?.noDeceased && (
          <Box sx={{ mt: 1 }}>
            <Alert severity="error">{item.errors?.noDeceased}</Alert>
          </Box>
        )}
        {item.errors?.specialCharacter && (
          <Box sx={{ mt: 1 }}>
            <Alert severity="error">{item.errors?.specialCharacter}</Alert>
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          value={item.deceaseds[0] || ''}
          onChange={(event) =>
            setItem({
              ...item,
              deceaseds: [event.target.value, item.deceaseds[1]],
            })
          }
          error={item.errors?.noDeceased || item.errors?.deceaseds0}
          helperText={item.errors?.deceaseds2}
          label="被超薦人(左)"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          value={item.deceaseds[1] || ''}
          onChange={(event) =>
            setItem({
              ...item,
              deceaseds: [item.deceaseds[0], event.target.value],
            })
          }
          error={item.errors?.noDeceased || item.errors?.deceaseds1}
          helperText={item.errors?.deceaseds2}
          label="被超薦人(右)"
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Typography variant="p" sx={{ fontWeight: 'bold' }}>
            陽上
          </Typography>
        </Box>
        <Box>
          <Typography variant="p" sx={{ fontSize: '12px', color: '#888' }}>
            陽上只取名字後二字
          </Typography>
        </Box>
        {item.errors?.noFamily && (
          <Box sx={{ mt: 1 }}>
            <Alert severity="error">{item.errors?.noFamily}</Alert>
          </Box>
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          value={item.family[0]}
          onChange={(event) => {
            const update = [...item.family];
            update[0] = event.target.value;
            setItem({ ...item, family: update });
          }}
          error={item.errors?.noFamily || item.errors?.family0}
          helperText={item.errors?.family0}
          label="陽上1"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          value={item.family[1]}
          onChange={(event) => {
            const update = [...item.family];
            update[1] = event.target.value;
            setItem({ ...item, family: update });
          }}
          error={item.errors?.noFamily || item.errors?.family1}
          helperText={item.errors?.family1}
          label="陽上2"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          value={item.family[2]}
          onChange={(event) => {
            const update = [...item.family];
            update[2] = event.target.value;
            setItem({ ...item, family: update });
          }}
          error={item.errors?.noFamily || item.errors?.family2}
          helperText={item.errors?.family2}
          label="陽上3"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          value={item.family[3]}
          onChange={(event) => {
            const update = [...item.family];
            update[3] = event.target.value;
            setItem({ ...item, family: update });
          }}
          error={item.errors?.noFamily || item.errors?.family3}
          helperText={item.errors?.family3}
          label="陽上4"
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          variant="outlined"
          value={item.family[4]}
          onChange={(event) => {
            const update = [...item.family];
            update[4] = event.target.value;
            setItem({ ...item, family: update });
          }}
          error={item.errors?.noFamily || item.errors?.family4}
          helperText={item.errors?.family4}
          label="陽上5"
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'right' }}>
        {state.index !== null && (
          <Button
            variant="contained"
            color="secondary"
            sx={{ mr: 2.5, p: 2, width: '150px' }}
            onClick={handleDelete}>
            刪除
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          sx={{ p: 2, width: '150px' }}
          onClick={handleNext}>
          儲存
        </Button>
      </Grid>
    </Grid>
  );
}
