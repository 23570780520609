import { Box, Typography } from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React from 'react';

export default function SuccessPage({ control }) {
  const [state, setState] = control;
  return (
    <Box sx={{ border: '1px dashed #ccc', borderRadius: 2, p: 5 }}>
      <Box
        sx={{ display: 'flex', alignItems: 'center', color: 'green', mb: 2 }}>
        <CheckCircleIcon sx={{ mr: 1.2 }} />
        <Typography
          variant="p"
          sx={{ color: 'green', fontWeight: 'bold', fontSize: '20px' }}>
          完成自助登記
        </Typography>
      </Box>
      <Box>
        <Typography variant="p">
          感謝您自助登記，請至服務處繳費完成報名。
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="p">
          總金額：$
          {state.items
            .reduce(
              (acc, cur) => acc + (cur.name === '特種牌位' ? 3000 : 2000),
              0
            )
            .toLocaleString()}
        </Typography>
      </Box>
    </Box>
  );
}
