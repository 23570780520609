import { Box, Button, Grid, TextField, Typography } from '@mui/material';

import React from 'react';

export default function StartPage({ control }) {
  const [state, setState] = control;
  const [errors, setErrors] = React.useState({});

  function validateMobile(mobile) {
    if (/^09\d{8}$/.test(mobile)) return true;
    return false;
  }

  function handleNext() {
    const errs = {};

    // check name
    if (state.name.length === 0) errs.name = '請填寫姓名';
    // check phone
    if (state.phone.length === 0) errs.phone = '請填寫行動電話';
    else if (!validateMobile(state.phone)) errs.phone = '行動電話格式錯誤';

    if (Object.keys(errs).length > 0) {
      return setErrors(errs);
    }

    setState({ ...state, stage: 'entry' });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="p"
            sx={{ color: '#444', fontWeight: 'bold', fontSize: '20px' }}>
            第一步：基本資料
          </Typography>
        </Box>
        <Box>
          <Typography variant="p">用於開立電子發票，請正確填寫。</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="報名人姓名"
          onChange={(event) => setState({ ...state, name: event.target.value })}
          error={!!errors.name}
          helperText={errors.name}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="outlined"
          label="行動電話"
          onChange={(event) =>
            setState({ ...state, phone: event.target.value })
          }
          error={!!errors.phone}
          helperText={errors.phone}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sx={{ textAlign: 'right' }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ p: 2, width: '150px' }}
          onClick={handleNext}>
          下一步
        </Button>
      </Grid>
    </Grid>
  );
}
